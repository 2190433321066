import React from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { adsBlueA, adsLightBlue, adsWhite } from "../utils/ADSColours";

const ContinueButton = ({continueHandler, title, enabled, hideIcon}) =>  {

    return (
        <Button
        disabled={!enabled}
        onClick={continueHandler}
        sx={{
          color: adsWhite,
          backgroundColor: adsBlueA,
          textTransform: "none",
          "&:hover": {
            backgroundColor: adsLightBlue,
            color: adsWhite,
          },
          "&.Mui-disabled": {
            background: "#eaeaea",
            color: "#c0c0c0"
          }
        }}
        startIcon={hideIcon ? null : <ArrowForwardIcon />}
      >
        {title ? title : "Continue"}
        
      </Button>
    );
}

export default ContinueButton;

