import React, { useState, useEffect, Fragment } from "react";

import {
  Typography,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
} from "@mui/material";

import { FormRowStyle, FormInputStyle } from "../../utils/AAStyles";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircleIcon from "@mui/icons-material/Circle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";
import { copyTextToClipboard, RndPasswordGenerator } from "../../utils/PasswordUtils";
import BackButton from "../../components/backButton";
import ContinueButton from "../../components/continueButton";
import ChangePasswordButton from "./../../components/changePasswordButton";

const strengthBad = -1;
const strengthMedium = 1;
const strengthGood = 10;
const maxPasswordLength = 20;

function checkPasswordStrength(pwd) {
  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})");
  var mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
  );

  if (strongRegex.test(pwd)) return strengthGood;
  if (mediumRegex.test(pwd)) return strengthMedium;

  return strengthBad;
}

const GridTabPanelManual = (props) => {
  const { showPanel, passwordHandler, confirmedHandler, passwordStrength, ...other } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);

  const [resetpassword, setResetPassword] = useState("");
  const [resetconfirmed, setResetConfirmed] = useState("");

  const handlePasswordChangeEvent = (event) => {
    if (
      !resetpassword ||
      (resetpassword && (resetpassword.length < maxPasswordLength || event.target.value.length < resetpassword.length))
    )
      setResetPassword(event.target.value);
  };

  const handleConfirmedChangeEvent = (event) => {
    if (
      !resetconfirmed ||
      (resetconfirmed &&
        (resetconfirmed.length < maxPasswordLength || event.target.value.length < resetconfirmed.length))
    )
      setResetConfirmed(event.target.value);
  };

  useEffect(() => {
    confirmedHandler(resetconfirmed === resetpassword);
  }, [resetconfirmed]);

  useEffect(() => {
    passwordHandler(resetpassword);
    confirmedHandler(resetconfirmed === resetpassword);
  }, [resetpassword]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return showPanel ? (
    <Grid container justifyContent="flex-start" alignItems="center" sx={FormRowStyle()}>
      <Grid item xs={12}>
        <DialogContent>
          <Typography variant="body2">Passwords must be:</Typography>
          <List dense>
            <ListItem key="passwordInfo1">
              <ListItemIcon>
                <CircleIcon sx={{ width: "12px", height: "12px" }} />
              </ListItemIcon>
              <ListItemText primary="between 5 and 20 characters long." />
            </ListItem>
            <ListItem key="passwordInfo2">
              <ListItemIcon>
                <CircleIcon sx={{ width: "12px", height: "12px" }} />
              </ListItemIcon>
              <ListItemText primary="have at least 4 unique characters." />
            </ListItem>
            <ListItem key="passwordInfo3">
              <ListItemIcon>
                <CircleIcon sx={{ width: "12px", height: "12px" }} />
              </ListItemIcon>
              <ListItemText primary="have uppercase and lowercase characters." />
            </ListItem>
            <ListItem key="passwordInfo4">
              <ListItemIcon>
                <CircleIcon sx={{ width: "12px", height: "12px" }} />
              </ListItemIcon>
              <ListItemText primary="have numbers." />
            </ListItem>
            <ListItem key="passwordInfo5">
              <ListItemIcon>
                <CircleIcon sx={{ width: "12px", height: "12px" }} />
              </ListItemIcon>
              <ListItemText primary="have special characters." />
            </ListItem>
          </List>
        </DialogContent>
      </Grid>
      <Grid item xs={3}>
        <Typography id="reset-text-label-password" variant="body2" color="initial" align="left">
          New Password*
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          autoComplete="new-password"
          key="reset-text-textfield-password"
          id="reset-text-textfield-password"
          sx={FormInputStyle()}
          type={showPassword ? "text" : "password"}
          fullWidth
          required
          variant="outlined"
          margin="dense"
          size="small"
          value={resetpassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                  sx={{
                    "&:hover": {
                      color: "#2A6EBB",
                    },
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handlePasswordChangeEvent}
          aria-labelledby="reset-text-label-password"
        />
      </Grid>

      <Grid item xs={3}>
        <Typography id="reset-text-label-confirm-password" variant="body2" color="initial" align="left">
          Retype Password*
        </Typography>
      </Grid>
      <Grid item xs={9} sx={{ marginBottom: 1 }}>
        <TextField
          autoComplete="new-password"
          key="reset-text-textfield-confirm-password"
          id="reset-text-textfield-confirm-password"
          sx={FormInputStyle()}
          type={showConfirmed ? "text" : "password"}
          fullWidth
          required
          variant="outlined"
          margin="dense"
          size="small"
          value={resetconfirmed}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmed(!showConfirmed)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                  sx={{
                    "&:hover": {
                      color: "#2A6EBB",
                    },
                  }}
                >
                  {showConfirmed ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleConfirmedChangeEvent(e)}
          aria-labelledby="reset-text-label-confirm-password"
        />
      </Grid>
      {passwordStrength && passwordStrength === strengthGood ? (
        <Alert severity="success">Password strength is good</Alert>
      ) : (
        <Fragment />
      )}
      {passwordStrength && passwordStrength === strengthMedium ? (
        <Alert severity="warning">
          Password strength is acceptable, make stronger by using more characters including special symbols
        </Alert>
      ) : (
        <Fragment />
      )}
      {passwordStrength && passwordStrength === strengthBad ? (
        <Alert severity="error">Password strength is poor, use more uppercase, lowercase, numbers and symbols</Alert>
      ) : (
        <Fragment />
      )}
    </Grid>
  ) : (
    <Fragment />
  );
};

const GridTabPanelAuto = (props) => {
  const autoPasswordLength = 14;
  const [autopassword, setAutoPassword] = useState(RndPasswordGenerator(autoPasswordLength));

  const { showPanel, copyHandler, passwordHandler } = props;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const GeneratePassword = () => {
    setAutoPassword(RndPasswordGenerator(autoPasswordLength));
  };

  useEffect(() => {
    if (!autopassword || autopassword.length === 0) {
      setAutoPassword(RndPasswordGenerator(autoPasswordLength));
    } else {
      const passwordStrength = checkPasswordStrength(autopassword);

      if (passwordStrength !== strengthGood) {
        setAutoPassword(RndPasswordGenerator(autoPasswordLength));
      } else {
        passwordHandler && passwordHandler(autopassword);
      }
    }
  }, [autopassword]);

  const copyPassword = () => {
    copyTextToClipboard(autopassword);
    copyHandler();
  };

  return showPanel ? (
    <Grid container justifyContent="flex-start" alignItems="center" sx={FormRowStyle()}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            <ContinueButton title="Regenerate" enabled hideIcon continueHandler={(e) => GeneratePassword()} />
          </Grid>
          <Grid item xs={8}>
            <TextField
              key="reset-text-textfield-autopassword"
              id="reset-text-textfield-autopassword"
              sx={FormInputStyle()}
              type="text"
              fullWidth
              required
              variant="outlined"
              margin="dense"
              size="small"
              value={autopassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy password"
                      onClick={() => copyPassword()}
                      onMouseDown={(e) => handleMouseDownPassword(e)}
                      edge="end"
                      sx={{
                        "&:hover": {
                          color: "#2A6EBB",
                        },
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              aria-labelledby="reset-text-label-autopassword"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Fragment />
  );
};

const ResetPasswordDialog = ({ closeDialogHandler, continueHandler, continueError, backHandler }) => {
  const theme = useTheme();

  const [password, setPassword] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState();

  const [copyAlert, setCopyAlert] = useState(false);
  const [autoPassword, setAutoPassword] = useState();

  const [continueEnabled, setContinueEnabled] = useState(false);
  const [error, setError] = useState(continueError);
  const [tabValue, setTabValue] = useState(0);

  const manuallyGenerated = 0;
  const autoGenerated = 1;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onCancelHandler = (event, reason) => {
    if (reason !== "backdropClick") closeDialogHandler();
  };

  useEffect(() => {
    if (tabValue === autoGenerated) setContinueEnabled(true);

    if (tabValue === manuallyGenerated) validateManualPassword();
  }, [tabValue]);

  useEffect(() => {
    if (password && password.length > 0) {
      const strength = checkPasswordStrength(password);
      setPasswordStrength(strength);
    }
  }, [password]);

  useEffect(() => {
    validateManualPassword();
  }, [passwordStrength, confirmed]);

  const validateManualPassword = () => {
    setContinueEnabled(!!password && confirmed && passwordStrength >= strengthMedium);
  };

  const handleContinueClick = (event) => {
    const newPassword = tabValue === manuallyGenerated ? password : autoPassword;
    continueHandler(newPassword);
  };

  return (
    <StyledWizardDialog
      onClose={(evt, reason) => onCancelHandler(evt, reason)}
      open
      aria-labelledby="password-reset-dialog"
      fullWidth
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <StyledWizardDialogTitle id="customized-dialog-title" onClose={(e) => onCancelHandler(e)} title="User Password" />

      <DialogContent sx={{ marginTop: theme.spacing(0) }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="">
          <Tab label="Manual" index={manuallyGenerated} />
          <Tab label="Auto" index={autoGenerated} />
        </Tabs>

        <Grid container justifyContent="flex-start" alignItems="center" sx={FormRowStyle()} role="tabpanel">
          <GridTabPanelManual
            showPanel={tabValue === manuallyGenerated}
            passwordHandler={(pwd) => setPassword(pwd)}
            passwordStrength={passwordStrength}
            confirmedHandler={(b) => setConfirmed(b)}
          />
          {tabValue === autoGenerated ? (
            <GridTabPanelAuto
              showPanel={tabValue === autoGenerated}
              passwordHandler={(pwd) => setAutoPassword(pwd)}
              copyHandler={() => setCopyAlert(true)}
            />
          ) : (
            <Fragment />
          )}
          <Grid item xs={12}>
            {!error ? <Fragment /> : <Alert severity="error">{error}</Alert>}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        {backHandler ? <BackButton backHandler={backHandler} /> : <Fragment />}
        {backHandler ? (
          <ContinueButton continueHandler={handleContinueClick} enabled={continueEnabled} />
        ) : (
          <ChangePasswordButton continueHandler={handleContinueClick} enabled={continueEnabled} />
        )}
      </DialogActions>
      <Snackbar open={copyAlert} autoHideDuration={2000} onClose={() => setCopyAlert(false)}>
        <Alert onClose={() => setCopyAlert(false)} severity="success" sx={{ width: "100%" }}>
          Password copied to clipboard
        </Alert>
      </Snackbar>
    </StyledWizardDialog>
  );
};

export default ResetPasswordDialog;
